import { useContext, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { Typography, useTheme } from '@mui/material';
import { useSigningURL } from 'api/api';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';

const EmploymentContractSignStep = () => {
  const theme = useTheme();
  const { goToStep, activeStep } = useWizard();
  const { onboardingStepsConfig } = useContext(OnboardingRequestContext);
  const { data: signingUrl, isFetching } = useSigningURL();

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      const data = (event as MessageEvent).data;

      if (data && String(data).split(':').includes('signing')) {
        const signingState = String(data).split(':')[1];

        if (signingState === 'complete') {
          goToStep(activeStep + 1);
        }

        if (signingState === 'postponed') {
          goToStep(
            onboardingStepsConfig.steps.findIndex(
              (step) => step.id === 'Dashboard',
            ),
          );
        }
      }
    };

    window.addEventListener('message', messageHandler as EventListener);

    return () =>
      window.removeEventListener('message', messageHandler as EventListener);
  });

  return (
    <>
      {isFetching && (
        <Typography variant="h6" color={theme.branding.textPrimaryColor}>
          Fetching your contract...
        </Typography>
      )}
      {signingUrl && (
        <iframe
          style={{
            width: '100%',
            border: 'none',
            flex: 1,
          }}
          title="signing"
          src={signingUrl}
        />
      )}
    </>
  );
};

export default EmploymentContractSignStep;
